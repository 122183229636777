<template>
  <div v-if="!isHiddenPage" class="back-button-component" @click="back()">
    <svg
      width="47"
      height="36"
      viewBox="0 0 35 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_bb)">
        <path d="M14 18L6 11L14 4" stroke="white" stroke-width="2" />
        <path
          d="M7.5 11H29.5859"
          stroke="white"
          stroke-width="2"
          stroke-linecap="square"
        />
      </g>
      <defs>
        <filter
          id="filter0_bb"
          x="0.481445"
          y="0.247437"
          width="34.1045"
          height="23.5052"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </div>
</template>
<script>
import { mapStores } from 'pinia';

export default {
  props: {
    fallbackPage: {
      type: String,
      default: 'me-rewards',
    },
  },
  data() {
    return {
      hiddenPages: ['me-rewards', 'me-challenges', 'me-profile', 'me-advent'],
    };
  },
  computed: {
    ...mapStores(useMainStore),
    isHiddenPage() {
      return this.hiddenPages.includes(this.$route.name);
    },
    firstPage() {
      return this.mainStore.from_url === '/';
    },
  },
  methods: {
    back() {
      if (this.firstPage) {
        this.$router.push({
          name: this.fallbackPage,
        });
      } else {
        this.$router.back();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.back-button-component {
  @apply fixed top-0 left-0 z-30 cursor-pointer;
}
</style>
