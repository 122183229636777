<template>
  <div class="text-center text-theme-primary">
    <p class="pb-4 text-4xl uppercase font-theme-title">
      {{ currencyNameTranslated(this.$t) }}
    </p>

    <p
      v-t="{ path: 'components.onboarding.default.slide2.top_text' }"
      class="text-base"
    />

    <div class="mx-12 my-6">
      <div
        v-for="step in steps"
        :key="step.id"
        class="flex justify-between my-2"
      >
        <div class="flex items-center">
          <img :src="`/img/onboarding/${step.id}.png`" class="w-8 h-8 mr-2" />
          <span
            v-t="`components.onboarding.default.slide2.steps.${step.id}`"
            class="mt-1"
          ></span>
        </div>
        <div class="flex items-center">
          <span class="mt-1 mr-2 text-lg font-bold">+{{ step.points }}</span>
          <img :src="currencyIcon" class="w-6 h-6" />
        </div>
      </div>
    </div>

    <p v-t="bottomText" class="text-base" />
  </div>
</template>

<script>
import { mapState } from 'pinia';

export default {
  props: {
    longBottomText: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    steps: [
      {
        id: 'read',
        points: 3,
      },
      {
        id: 'video',
        points: 5,
      },
      {
        id: 'comment',
        points: 6,
      },
    ],
  }),
  computed: {
    ...mapState(useMeStore, ['currencyIcon', 'currencyNameTranslated']),
    bottomText() {
      return this.longBottomText
        ? 'components.onboarding.default.slide2.bottom_text_long'
        : 'components.onboarding.default.slide2.bottom_text';
    },
  },
};
</script>
