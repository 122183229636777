<template>
  <div class="onboarding-slide2">
    <img class="icon" :src="currencyIcon" />
    <div class="content">
      <OnboardingLoggedOut />

      <RegisterButton class="mb-8 button">
        {{ $t('buttons.register_now') }}
      </RegisterButton>

      <p
        v-t="{ path: 'components.onboarding.default.letsgo' }"
        class="thanks"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'pinia';

export default {
  computed: {
    ...mapState(useMeStore, ['currencyIcon']),
  },
};
</script>

<style lang="scss" scoped>
.onboarding-slide2 {
  @apply px-4;
}

.content {
  @apply bg-white px-2 shadow-box-lg -mt-12 pt-12 rounded-theme-small relative pb-4;
}

.icon {
  @apply mx-auto h-24 z-20 relative;
}

.title {
  @apply text-theme-primary font-theme-title text-4xl text-center uppercase;
}

.text {
  @apply text-theme-primary text-base text-center;
}

.text-big {
  @apply text-theme-primary text-xl text-center;
}

.button {
  @apply mt-4 mx-auto block text-center w-2/3;
}

.button > button {
  @apply w-full h-8
}

.thanks {
  @apply absolute text-theme-primary font-black text-base text-center bottom-0 left-0 right-0 pb-4 cursor-pointer w-full;
}

.reward {
  @apply mx-auto mt-2;
}
</style>
