<template>
  <section class="components-error-container">
    <div class="topimg min-h-48 base:min-h-56">
      <img src="/img/bg/error.png" class="w-full" />
    </div>
    <div class="components-error-container-body">
      <div class="header">
        <div class="min-h-20">
          <img class="h-20 mx-auto" src="/img/pages/reward.png" />
        </div>
        <p v-t="{ path: 'pages.error.header' }" class="title" />
      </div>
      <div class="body">
        <div class="description">
          <div class="text">
            <p
              v-t="{
                path: 'pages.error.headertext',
                args: {
                  currency: meStore.currencyNameTranslated($t),
                },
              }"
            />
            <div v-if="canRetry" class="retry-container">
              <p v-t="{ path: 'buttons.retry' }" @click="retry" />
            </div>
            <p
              v-t="{
                path: 'pages.error.token',
                args: {
                  token: meStore.supportToken,
                },
              }"
              class="pt-4"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapStores } from 'pinia';

export default {
  computed: {
    ...mapStores(useMeStore),
    canRetry() {
      return (
        this.$route.query.retry === undefined &&
        this.meStore.collector_token !== undefined &&
        this.meStore.collector_token !== ''
      );
    },
  },
  methods: {
    retry() {
      this.mainStore.reloadApp('&retry=1');
    },
  },
};
</script>

<style lang="scss" scoped>
.components-error-container {
  @apply bg-white;
}

.topimg {
  @apply bg-theme-header;
}

.components-error-container-body {
  @apply -mt-20;
}

.header {
  @apply text-center z-10 relative;
}

.body {
  @apply bg-white text-theme-primary px-4 pb-6 relative -mt-32 pt-28;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}

.title {
  @apply font-theme-title text-theme-primary text-4xl uppercase mt-2;
}

.description {
  @apply z-10 relative mt-8 text-theme-primary px-4;
}

.text {
  @apply text-center pt-4;
}

.retry-container {
  @apply underline pt-4;
}
</style>
