<template>
  <div class="layouts-default-container iframe-fix">
    <EssentialsBackButton />

    <Error v-if="mainStore.AppState == 'error'" />
    <div v-else>
      <slot class="pb-24" />
      <Menu v-if="isMenuPage" />
      <OnboardingLayer v-if="isOnboardingPage" />
    </div>

    <EssentialsCloseButton
      v-if="
        mainStore.platform === 'desktop' || mainStore.platform === 'mobileweb'
      "
    />
  </div>
</template>

<script>
import { mapStores } from 'pinia'

export default {
  data() {
    return {
      onboarding: null,
      onboardingLoggedIn: null,
      menuPages: [
        'me-rewards',
        'me-profile',
        'me-profile-rewards',
        'me-challenges',
        'me-faq',
        'me-leaderboard',
        'me-friends',
        'me-moreFriends',
        'me-special-slug',
        'me-voucher-id',
        'me-rewards-vplus',
      ],
      onboardingPages: [
        'me-rewards',
        'me-profile',
        'me-challenges',
        'me-faq',
        'me-leaderboard',
        'me-friends',
        'me-moreFriends',
        'me-special-slug',
      ],
    }
  },
  head() {
    return {
      title: this.mainStore.meta_title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.mainStore.meta_description,
        },
      ],
    }
  },
  computed: {
    ...mapStores(useMainStore),
    isMenuPage() {
      return this.menuPages.includes(this.$route.name)
    },
    isOnboardingPage() {
      return this.onboardingPages.includes(this.$route.name)
    },
  },
  mounted() {
    this.$bugsnag.addMetadata('lpUser', {
      collector_token: this.$route.query.collector_token,
      platform: this.$route.query.platform,
      hash: this.$route.query.hash,
      token: this.$route.query.token,
    })
  },
}
</script>

<style lang="scss" scoped>
.layouts-default-container {
  @apply bg-white h-full min-h-screen w-full font-theme-text leading-tighter;
  user-select: none;
}

.iframe-fix {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

@supports (-webkit-overflow-scrolling: touch) {
  .iframe-fix {
    overflow: scroll;
  }
}
</style>
