<template>
  <div class="onboarding-slide2">
    <img class="icon" :src="meStore.currencyIcon" />
    <div class="content">
      <template v-if="raffleLoaded">
        <p class="title">{{ meStore.currencyNameTranslated($t) }}</p>

        <div class="text-center">
          <span
            class="mx-4 text"
            v-html="
              $t('components.onboarding.default.slide2.loggedin_text_start', {
                reward_title: reward.title,
              })
            "
          />
          <span
            class="text"
            v-html="
              $t('components.onboarding.default.slide2.loggedin_text_second', {
                reward_title: reward.title,
              })
            "
          />
        </div>

        <div>
          <CardsRewardSmall
            v-if="rewardLoaded"
            :item="reward"
            class="reward"
            :hide-labels="true"
            :hide-details="true"
            @navigated="$emit('close')"
          />

          <EssentialsLoading v-else class="loading" />
        </div>
      </template>

      <template v-else>
        <OnboardingLoggedOut :long-bottom-text="false" />
      </template>

      <p
        v-if="raffleLoaded"
        class="mt-4 text"
        v-html="$t('components.onboarding.default.slide2.loggedin_text_end')"
      />

      <div class="button">
        <EssentialsDefaultButtonEvent @click="$emit('close')">{{
          $t('components.onboarding.default.letsgo')
        }}</EssentialsDefaultButtonEvent>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const rewardsStore = useRewardsStore();
const meStore = useMeStore();

await rewardsStore.fetchMyCurrentRaffles();

const raffle = computed(() => {
  const raffles = rewardsStore.myCurrentRaffles;
  if (raffles.length > 0) {
    return raffles[0];
  }
  return {};
});
const reward = computed(() => rewardsStore.singleReward.data);
const raffleLoaded = computed(() =>
  Object.hasOwnProperty.call(raffle.value, 'id')
);
const rewardLoaded = computed(() =>
  Object.hasOwnProperty.call(reward.value, 'title')
);

watch(raffle, () => {
  if (raffle.value.id) {
    rewardsStore.fetchSingleReward(raffle.value.id);
  }
});
</script>

<style lang="scss" scoped>
.onboarding-slide2 {
  @apply px-4;
}

.content {
  @apply bg-white px-2 shadow-box-lg -mt-12 pt-12 rounded-theme-small relative pb-4;
}

.icon {
  @apply mx-auto h-24 z-20 relative;
}

.title {
  @apply text-theme-primary font-theme-title text-4xl text-center uppercase;
}

.text {
  @apply text-theme-primary text-base text-center;
}

.text-big {
  @apply text-theme-primary text-xl text-center;
}

.button {
  @apply mt-4 mx-auto block text-center w-2/3;
}

.button > button {
  @apply w-full h-8
}

.thanks {
  @apply absolute text-theme-primary font-black text-base text-center bottom-0 left-0 right-0 pb-4 cursor-pointer w-full;
}

.reward {
  @apply mx-auto mt-2;
}
</style>
