<template>
  <div v-if="show" class="onboarding-component">
    <img class="bg" src="/img/bg/onboarding.png" />
    <swiper-container
      class="onboarding-swiper"
      ref="onboardingSwiper"
      @slideChangeTransitionStart="getSlide"
      pagination="true"
      spaceBetween="20"
      slidesPerView="auto"
    >
      <swiper-slide>
        <OnboardingSlide1 @close="closeOnboarding()" @next="nextSlide()" />
      </swiper-slide>

      <swiper-slide v-if="anonymousCollector">
        <OnboardingSlide2 @close="closeOnboarding()" />
      </swiper-slide>
      <swiper-slide v-else>
        <OnboardingSlide2_loggedin @close="closeOnboarding()" />
      </swiper-slide>
    </swiper-container>
    <img
      v-if="!lastSlide"
      src="~/assets/img/onboard/next_slide.png"
      class="absolute bottom-0 right-0 z-50"
      @click="nextSlide()"
    />
  </div>
</template>
<script>
import { mapStores } from 'pinia';

export default {
  data() {
    return {
      lastSlide: false,
      show: false,
    };
  },
  computed: {
    ...mapStores(useMeStore),
    anonymousCollector() {
      return this.meStore.anonymous;
    },
  },
  mounted() {
    this.meStore.$subscribe(() => {
      this.showOnboarding();
    });

    if (this.meStore.meta !== undefined && this.meStore.meta !== null) {
      this.showOnboarding();
    }
  },
  methods: {
    nextSlide() {
      this.$refs.onboardingSwiper.swiper.slideNext();
    },
    showOnboarding() {
      let hasSeenOnboarding = false;
      this.meStore.meta.forEach((meta) => {
        if (meta.key === 'sawOnboarding') {
          hasSeenOnboarding = true;
        }
      });

      if (!hasSeenOnboarding) {
        this.show = true;
      }
    },
    closeOnboarding() {
      this.show = false;
      this.meStore.sawOnboarding({ type: 'default' });
    },
    getSlide() {
      if (this.$refs.onboardingSwiper) {
        this.lastSlide = this.$refs.onboardingSwiper.swiper.isEnd;
        return;
      }

      this.lastSlide = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding-component {
  @apply fixed w-full h-full top-0;
  z-index: 60;
}

.bg {
  @apply absolute h-full w-full;
}

.onboarding-swiper {
  @apply mt-4;
  height: 95%;
}

.swiper-slide {
  @apply overflow-y-scroll
}
</style>
